import {
  ConversationMessage,
  ConversationMessagesPayload, ConversationsPayload, IConversation, SendSmsBody,
} from 'types/IConversation';
import { ISmsAutoReplyModel, SmsAutoReplyPayload, SmsAutoReplyValidateKeyword } from 'types/ISmsAutoReplies';
import API from './API';

type Params = {
  page: number
  perpage: number
  searchValue?: string
  ordering?: 'newest'|'oldest'
  readStatus?: 'all'|'read'|'unread'
  senior?: boolean
  staff?: boolean
  family?: boolean
}

type SmsAutoReplyParams = {
  page: number
  perpage: number
  sortField: string
  sortDirection: string
  searchField: string
  searchValue: string
}

export const sendSms = async (data: SendSmsBody): Promise<void> => {
  await API.post<void>('/api/v2/Inbox/Message/Send', data);
}

export const markAsRead = async (conversationId: string, isRead: boolean) => {
  const response = await API.put<IConversation>(`/api/v2/Inbox/${conversationId}/isRead`, {
    isRead,
  });

  return response.data;
}

export const hideMessage = async (conversationId: string, messageId: string, IsHidden: boolean) => {
  const response = await API.put<ConversationMessage>(`/api/v2/Inbox/${conversationId}/${messageId}/IsHidden`, {
    IsHidden,
  });

  return response.data;
}

export const getInboxConversationMessages = async (conversationId: string, {
  page,
  perpage,
}: Pick<Params, 'page'|'perpage'>): Promise<ConversationMessagesPayload> => {
  const response = await API.get<ConversationMessagesPayload>(
    `/api/v2/Inbox/SMSConversations/${conversationId}/Messages`,
    {
      params: {
        page,
        perpage,
      },
    });

  return response.data;
}

export const getInboxConversations = async ({
  page,
  perpage,
  searchValue,
  ordering,
  readStatus,
  senior,
  staff,
  family,
}: Params): Promise<ConversationsPayload> => {
  const response = await API.get<ConversationsPayload>('/api/v2/Inbox/smsConversations', {
    params: {
      page,
      perpage,
      searchValue: searchValue || undefined,
      ordering,
      readStatus,
      senior,
      staff,
      family,
    },
  });

  return response.data;
}

export const getUnreadMessageCount = async () => {
  const response = await API.get<number>('/api/v2/Inbox/UnreadConversationCount');

  return response.data;
}

export const getSmsAutoReplies = async ({
  searchField,
  searchValue,
  sortField,
  sortDirection,
  page,
  perpage,
}: SmsAutoReplyParams): Promise<SmsAutoReplyPayload> => {
  const response = await API.get<SmsAutoReplyPayload>('/api/v2/Inbox/SmsAutoReplies', {
    params: {
      searchField,
      searchValue: searchValue || undefined,
      sortField,
      sortDirection,
      page,
      perpage,
    },
  });

  return response.data;
}

export async function deleteSmsAutoReply(replyId: string): Promise<void> {
  await API.delete(`/api/v2/Inbox/SmsAutoReply/${replyId}`);
}

export const getSmsAutoReplyById = async (replyId: string): Promise<ISmsAutoReplyModel> => {
  if (!replyId) {
    return null;
  }
  const response = await API.get(`/api/v2/Inbox/SmsAutoReply/${replyId}`);
  return response.data;
}

export const createSmsAutoReply = async (reply: ISmsAutoReplyModel) => {
  await API.post('/api/v2/Inbox/SmsAutoReply', reply);
}

export const updateSmsAutoReply = async (reply: ISmsAutoReplyModel) => {
  await API.put(`/api/v2/Inbox/SmsAutoReply/${reply.SmsAutoReplyId}`, reply);
}

export const validateKeywordInUsed = async (newKeyword: string): Promise<SmsAutoReplyValidateKeyword> => {
  const response = await API.post('/api/v2/Inbox/SmsAutoReply/Keyword', { keyword: newKeyword });
  return response.data;
}
