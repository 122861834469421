import { add, format, parse } from 'date-fns';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Dropdown, DropdownButton, ButtonGroup, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { SearchInput } from 'components/Inputs/SearchInput';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import Select from 'components/Inputs/Select';
import useTags from 'hooks/useTags';

import DatePicker from 'react-datepicker';
import { RootState } from 'types/rootState';
import { useSurveyConfig } from './useSurveyConfig';

const MyDateRangePicker = ({
  filterValue, setFilterValue, onStateChange, hasNoteChanged, setCurrentPage,
}) => {
  const [focusedInputState, setFocusedInput] = useState<FocusedInputShape>(null);
  const [myStartDate, setMyStartDate] = useState(moment(filterValue.startDate));
  const [myEndDate, setMyEndDate] = useState(moment(filterValue.endDate))
  const closeIcon = () => (
    <div className="dateCloseButton">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => { setFocusedInput(null) }}
      >
        Ok
      </button>
    </div>
  );
  return (
    <DateRangePicker
      minDate={moment().subtract(100, 'years')}
      startDate={myStartDate} // momentPropTypes.momentObj or null,
      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
      endDate={myEndDate} // momentPropTypes.momentObj or null,
      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => {
        if (hasNoteChanged) {
          onStateChange(() => {
            setMyStartDate(startDate);
            setMyEndDate(endDate);

            if (startDate && endDate) {
              setFilterValue({
                startDate: startDate.format('yyyy-MM-DD'),
                endDate: endDate.format('yyyy-MM-DD'),
              })
            }
          }, () => { })
        } else {
          setMyStartDate(startDate);
          setMyEndDate(endDate);

          if (startDate && endDate) {
            setFilterValue({
              startDate: startDate.format('yyyy-MM-DD'),
              endDate: endDate.format('yyyy-MM-DD'),
            })
          }
        }
      }} // PropTypes.func.isRequired,
      focusedInput={focusedInputState} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      hideKeyboardShortcutsPanel
      minimumNights={0}
      // minDate: momentPropTypes.momentObj,
      // maxDate: momentPropTypes.momentObj,
      enableOutsideDays
      keepOpenOnDateSelect
      isOutsideRange={() => false}
      renderCalendarInfo={() => closeIcon()}
    />
  )
}

const TagSelect = ({
  availableTags,
  filterValue,
  setFilterValue,
  tagsLoading,
  onStateChange,
  hasNoteChanged,
  setCurrentPage,
}: {
  availableTags: string[]
  setFilterValue: (val: any) => any
  filterValue: any
  tagsLoading: boolean
  hasNoteChanged: boolean
  onStateChange: (acceptedFunction: any, rejectedFunction: any) => void
  setCurrentPage: (newValue: number, updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn') => void
}) => (
  !tagsLoading && (
    <div className="tags">
      <Select
        id="tag"
        name="tag"
        label=""
        value={filterValue.text}
        onChange={(e) => {
          if (hasNoteChanged) {
            onStateChange(() => {
              setFilterValue({ text: e });
            }, () => { })
          } else {
            setFilterValue({ text: e });
          }
        }}
        options={availableTags.map((x) => ({ label: x, value: x }))}
      />
    </div>
  )
)

const StatusSelect = ({
  availableStatuses,
  filterValue,
  setFilterValue,
  onStateChange,
  hasNoteChanged,
  setCurrentPage,
}: {
  availableStatuses: string[]
  setFilterValue: (val: any) => any
  filterValue: any
  onStateChange: (acceptedFunction: any, rejectedFunction: any) => void
  hasNoteChanged: boolean
  setCurrentPage: (newValue: number, updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn') => void
}) => (
  availableStatuses && (
    <Select
      id="SearchField"
      name="SearchField"
      label=""
      value={filterValue.programStatus}
      onChange={(e) => {
        if (hasNoteChanged) {
          onStateChange(() => {
            setFilterValue({ programStatus: e });
          }, () => { })
        } else {
          setFilterValue({ programStatus: e });
        }
      }}
      options={availableStatuses.map((x) => ({ label: x, value: x }))}
    />
  )
)

const MyDatePicker = ({ filterValue, setFilterValue, setCurrentPage }) => (
  <DatePicker
    className="form-control date"
    selected={
      parse(filterValue.endDate, 'yyyy-MM-dd', new Date())
    }
    onChange={(date: Date) => {
      setFilterValue({ endDate: format(date, 'yyyy-MM-dd') });
    }}
    isClearable={false}
  />
);
const CallToReviewReasonSelect = ({
  setCurrentPage, availableReasons, filterValue, setFilterValue, onStateChange, hasNoteChanged,
}: {
  setCurrentPage: (newValue: number, updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn') => void
  availableReasons: {label: string, value: string}[]
  setFilterValue: (val: any) => any
  filterValue: any
  hasNoteChanged: boolean
  onStateChange: (acceptedFunction: any, rejectedFunction: any) => void
}) => (
  (
    <Select
      id="callToReviewReason"
      name="callToReviewReason"
      label=""
      value={filterValue.text}
      onChange={(e) => {
        if (hasNoteChanged) {
          onStateChange(() => {
            setFilterValue({ text: e });
          }, () => { })
        } else {
          setFilterValue({ text: e });
        }
      }}
      options={availableReasons}
    />
  )
)

const SORT_KEYS = {
  FirstName: {
    label: 'First Name',
    value: 'FirstName',
  },
  LastName: {
    label: 'Last Name',
    value: 'LastName',
  },
  DischargeDate: {
    label: 'Discharge Date',
    value: 'DischargeDate',
  },
  Facility: {
    label: 'Facility',
    value: 'Facility',
  },
  NextAutoCallDate: {
    label: 'Next Auto Call',
    value: 'NextAutoCallDate',
  },
  NextManualCallDate: {
    label: 'Next Manual Call',
    value: 'NextManualCallDate',
  },
  LastCallDate: {
    label: 'Last Call',
    value: 'LastCallDate',
  },
};

type Props = {
  setCurrentPage: (newValue: number, updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn') => void
  onStateChange: (acceptedFunction: any, rejectedFunction: any) => void
  onSearchParamsChange: (params) => void
  hasNoteChanged: boolean
  selectedSort: string
  setSelectedSort: (field: string) => void
  sortDirection: string
  setSortDirection: (direction: string) => void
  onSortParamsChange: (params) => void
};

export const DischargedPatientsFilterControl = (
  {
    setCurrentPage,
    onStateChange,
    onSearchParamsChange,
    onSortParamsChange,
    hasNoteChanged,
    setSelectedSort, selectedSort, sortDirection, setSortDirection,
  }: Props,
): React.ReactElement => {
  const availableTags = useTags();
  const surveyConfig = useSurveyConfig();
  const callToReviewReasons = [
    { label: 'All', value: 'all' },
    { label: 'Flagged answer', value: 'alert' },
    { label: 'Missed call', value: 'no-answer' },
    { label: 'Transferred', value: 'transfer' },
  ];
  const eventKeys = {
    upcomingcalls: {
      title: 'Upcoming Calls',
      inputType: 'dateRange',
      defaultData: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(add(new Date(), { days: 7 }), 'yyyy-MM-dd'),
      },
    },
    tags: {
      title: 'Tag',
      inputType: 'tags',
      defaultData: {
        text: availableTags.tags[0],
      },
    },
    callstoreview: {
      title: 'Calls to Review',
      inputType: 'callstoreview',
      defaultData: {
        text: callToReviewReasons[0].value,
      },
    },
    completedcalls: {
      title: 'Completed Calls',
      inputType: 'dateRange',
      defaultData: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(add(new Date(), { days: 7 }), 'yyyy-MM-dd'),
      },
    },
    // id: { title: 'Discharged Patient Id', inputType: 'text' },
    // customprofileid: { title: 'Patient Customer Profile Id', inputType: 'text' },
    // contactcustomerprofileid: { title: 'Contact Customer Profile Id', inputType: 'text' },
    dischargedate: {
      title: 'Discharge Date',
      inputType: 'dateRange',
      defaultData: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(add(new Date(), { days: 7 }), 'yyyy-MM-dd'),
      },
    },
    patientname: {
      title: 'Patient Name',
      inputType: 'text',
      defaultData: {
        text: '',
      },
    },
    programstatus: {
      title: 'Program Status',
      inputType: 'status',
      defaultData: {
        programStatus: surveyConfig?.StartState,
      },
    },
  };
  const [selectedFilter, setSelectedFilter] = useState<string>('upcomingcalls');
  const [filterValue, setFilterValue] = useState<Record<string, any>>(eventKeys[selectedFilter].defaultData);
  const [searchQuery, setSearchQuery] = useState('');
  const patientTimeStamp = useSelector((state: RootState) => state.DischargedPatients.timeStamp);
  useEffect(() => {
    const params = {
      searchField: selectedFilter,
      searchValue: filterValue,
      sortDirection,
      sortField: selectedSort,
    };
    onSearchParamsChange(params);
  }, [filterValue, selectedFilter, patientTimeStamp, onSearchParamsChange, sortDirection, selectedSort]);

  const sortKeys = useMemo(() => {
    if (selectedFilter === 'callstoreview') {
      return { ...SORT_KEYS, CallsPressed9: { label: "Calls Pressed '9'", value: 'CallsPressed9' } };
    }

    if (!SORT_KEYS[selectedSort]) {
      // once calls pressed 9 option has been removed, auto switch to default sort option.
      setSelectedSort('LastName');
    }
    return SORT_KEYS;
  }, [selectedSort, selectedFilter, SORT_KEYS]);

  const selectedSortKeyLabel = useMemo(() => {
    if (selectedFilter !== 'callstoreview' && selectedSort === 'CallsPressed9') {
      setSelectedSort('LastName');
      return 'Last Name';
    }

    return sortKeys[selectedSort]?.label;
  }, [sortKeys, selectedFilter, selectedSort]);

  const inputs = {
    none: (
      <div className="form-group SearchInput floating-label active">
        <input className="form-control" type="text" disabled />
      </div>
    ),
    tags: (
      <TagSelect
        availableTags={availableTags.tags}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        tagsLoading={availableTags.loading}
        onStateChange={onStateChange}
        hasNoteChanged={hasNoteChanged}
        setCurrentPage={setCurrentPage}
      />
    ),
    status: (
      <StatusSelect
        availableStatuses={surveyConfig?.States}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        onStateChange={onStateChange}
        hasNoteChanged={hasNoteChanged}
        setCurrentPage={setCurrentPage}
      />
    ),
    date: (
      <MyDatePicker
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setCurrentPage={setCurrentPage}
      />),
    dateRange: (
      <div
        className="form-group datepicker"
      >
        <MyDateRangePicker
          key={selectedFilter}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          onStateChange={onStateChange}
          hasNoteChanged={hasNoteChanged}
          setCurrentPage={setCurrentPage}
        />
      </div>),
    text: (
      <SearchInput
        className=""
        placeholder="I am looking for..."
        name="searchValue"
        value={searchQuery}
        onButtonClick={(): void => {
          if (hasNoteChanged) {
            onStateChange(() => {
              setFilterValue({ text: searchQuery });
            }, () => { })
          } else {
            setFilterValue({ text: searchQuery });
          }
        }}
        onChange={(evt) => {
          setSearchQuery(evt.target.value);
        }}
        onKeyDown={(evt) => {
          if (evt.key !== 'Enter') {
            return;
          }
          evt.preventDefault();
          if (hasNoteChanged) {
            onStateChange(() => {
              setFilterValue({ text: searchQuery });
            }, () => { })
          } else {
            setFilterValue({ text: searchQuery });
          }
        }}
      />
    ),
    callstoreview: (
      <div className="calltoreviewreason">
        <CallToReviewReasonSelect
          setCurrentPage={setCurrentPage}
          availableReasons={callToReviewReasons}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          onStateChange={onStateChange}
          hasNoteChanged={hasNoteChanged}
        />
      </div>
    ),
  };
  return (
    <ButtonGroup className="filter-interface">
      <DropdownButton
        variant="secondary"
        size="lg"
        title={eventKeys[selectedFilter].title}
        id="input-group-dropdown-1"
        onSelect={(eventKey) => {
          if (hasNoteChanged) {
            onStateChange(() => {
              setFilterValue(eventKeys[eventKey].defaultData)
              setSelectedFilter(eventKey);
            }, () => {

            })
          } else {
            setFilterValue(eventKeys[eventKey].defaultData)
            setSelectedFilter(eventKey);
          }
        }}
      >
        {Object.keys(eventKeys).sort().map((x) => (
          <Dropdown.Item key={x} eventKey={x}>{eventKeys[x].title}</Dropdown.Item>))}
      </DropdownButton>
      <div className="control-container">
        {inputs[eventKeys[selectedFilter].inputType]}
      </div>
      <DropdownButton
        variant="secondary"
        size="lg"
        title={`Sort By: ${selectedSortKeyLabel}`}
        id="input-group-dropdown-2"
        onSelect={(sortKey) => {
          setSelectedSort(sortKey);
          const params = {
            searchField: selectedFilter,
            searchValue: filterValue,
            sortField: sortKey,
            sortDirection,
          };
          onSortParamsChange(params);
        }}
      >
        {Object.keys(sortKeys).sort().map((x) => (
          <Dropdown.Item key={x} eventKey={x}>{sortKeys[x].label}</Dropdown.Item>))}
      </DropdownButton>
      <Button
        size="lg"
        id="sort-direction-btn"
        variant="secondary"
        onClick={() => {
          if (sortDirection === 'asc') {
            setSortDirection('desc');
            const params = {
              searchField: selectedFilter,
              searchValue: filterValue,
              sortField: selectedSort,
              sortDirection: 'desc',
            };
            onSortParamsChange(params);
          } else {
            setSortDirection('asc');
            const params = {
              searchField: selectedFilter,
              searchValue: filterValue,
              sortField: selectedSort,
              sortDirection: 'asc',
            };
            onSortParamsChange(params);
          }
        }}
      >
        <FontAwesomeIcon icon={sortDirection === 'asc' ? 'sort-alpha-down' : 'sort-alpha-up'} />
      </Button>
    </ButtonGroup>
  );
};
