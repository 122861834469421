import React from 'react';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { hasRole } from 'hooks/useHasRoles';
import { hasPermission } from 'hooks/useHasPermissions';

import { hasFeature } from 'reducers/UserInfo';
import { UnreadMessageCount } from 'components/UnreadMessageCount';
import { INavItem } from '../components/NavItem';
import { segmentAnalyticsTrack, trackActions } from './SegmentTool';

const isAdmin = (userInfo: IUserInfoState) => hasRole(userInfo.roles, 'Super') || hasRole(userInfo.roles, 'Admin');
const isSuper = (userInfo: IUserInfoState) => hasRole(userInfo.roles, 'Super');
const isVFCorpAccount = (userInfo: IUserInfoState) => userInfo.userInfo?.AccountDetail.AccountID == 102;

const MainNavItems: INavItem[] = [
  {
    label: 'Calendar',
    icon: 'calendar-day',
    target: '/calendar',
    shouldDisplay: (userInfo: IUserInfoState): boolean => (
      isAdmin(userInfo)
      || hasPermission(userInfo.permissions, 'Calendar:Read')
    ),
  },
  {
    label: 'Messaging',
    renderLabel: ({ isOpen, label }) => (isOpen
      ? label
      : (
        <>
          <span className="text">{label}</span>
          <UnreadMessageCount />
        </>
      )),
    icon: 'th-list',
    target: '/events',
    matchLocations: [
      '/events',
      '/events/create',
      '/templates/',
      '/librarycontent',
      '/smsinbox',
    ],
    children: [
      {
        label: 'Templates',
        target: '/templates',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Templates:Read')
        ),
      },
      {
        label: 'Automatic Events',
        target: '/events/automatic',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Calendar:Read')
          || hasPermission(userInfo.permissions, 'Calendar:Create')
        ),
      },
      {
        label: 'Library',
        target: '/librarycontent',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Library:Read')
        ),
      },
      {
        label: 'SMS Inbox',
        renderLabel: ({ isParentOpen, label }) => (!isParentOpen
          ? label
          : (
            <>
              <UnreadMessageCount className="beforeText" />
              <span className="text">{label}</span>
            </>
          )),
        target: '/smsinbox',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Inbox:Read'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'sms-inbox')
        ),
      },
      {
        label: 'Marketing',
        target: '/marketing-templates',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Marketing:Send'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'marketing-emails')
        ),
      },
      {
        label: 'Broadcast History',
        target: '/broadcast-history',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Calendar:Read'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'search-event-history')
        ),
      },
      {
        label: 'SMS Auto-reply',
        target: '/sms-auto-reply',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Sms Auto Reply:Read'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'sms-inbox')
        ),
      },
    ],
  },
  {
    label: 'People',
    icon: 'th-list',
    target: '/profiles',
    children: [
      {
        label: 'Profiles',
        icon: 'user',
        target: '/profiles',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Profiles:Read')
        ),
      },
      {
        label: 'Groups',
        icon: 'users',
        target: '/groups',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Groups:Read')
        ),
      },
    ],
  },
  {
    label: 'Engagement',
    icon: 'th-list',
    target: '/engagements',
    children: [
      {
        label: 'Activities Calendar',
        target: '/linkCaremerge?page=cc',
        external: true,
        useNewWindow: true,
      },
      {
        label: 'Community Engagement',
        target: '/linkCaremerge?page=ce',
        external: true,
        useNewWindow: true,
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'caremerge-profile-sync')
        ),
      },
      {
        label: 'Engagement Admin',
        target: '/linkCaremerge?page=dashboard',
        external: true,
        useNewWindow: true,
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'caremerge-profile-sync')
        ),
      },
    ],
    shouldDisplay: (
      userInfo: IUserInfoState,
    ): boolean => userInfo.userInfo?.AccountDetail.CaremergeFacilityID > 0,
  },
  {
    label: 'Applications',
    icon: 'comment-alt',
    target: '/profiles',
    children: [
      {
        label: 'Accushield Visitors',
        target: '/accushield',
        shouldDisplay: (
          userInfo: IUserInfoState,
        ): boolean => hasRole(userInfo.roles, 'Accushield'),
      },
      {
        label: 'Virtual Meeting',
        target: '/legacy/virtualmeeting',
        shouldDisplay: (userInfo: IUserInfoState):boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Virtual Meeting:Read')
        ),
      },
      {
        label: 'Appointment Reminders',
        target: '/legacy/appointmentreminders',
        shouldDisplay: (userInfo: IUserInfoState):boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Appointment Reminders:Read')
        ),
      },
      {
        label: 'Wellness & Check-In',
        target: '/legacy/wellness',
        shouldDisplay: (userInfo: IUserInfoState):boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Survey & Check In:Read')
        ),
      },
      {
        label: 'Feedback360',
        target: '/feedback360',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Calendar:Read'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'broadcast-forms')
        ),
      },
      {
        label: 'Phone Survey',
        target: '/events/survey',
        shouldDisplay: (userInfo: IUserInfoState):boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Calendar:Read')
        ),
      },
      {
        label: 'Activity Attendance',
        target: '/legacy/attendance',
        shouldDisplay: (userInfo: IUserInfoState):boolean => (
          isAdmin(userInfo)
          || hasPermission(userInfo.permissions, 'Activity Attendence:Read')
        ),
      },
    ],
  },
  {
    label: 'Discharged Patients',
    icon: 'hospital-user',
    target: '',
    shouldDisplay: (userInfo: IUserInfoState): boolean => (
      hasRole(userInfo.roles, 'DischargedPatients')
    ),
    children: [
      {
        label: 'Activity & Status',
        target: '/dischargedpatients',
      },
      {
        label: 'Dashboard',
        target: '/dischargedpatients-dashboard',
      },
      {
        label: 'Survey Question Lists',
        target: '/surveyQuestionLists',
        shouldDisplay: (userInfo: IUserInfoState) => isSuper(userInfo),
      },
    ],
  },
  {
    label: 'Enterprise',
    icon: 'th-list',
    target: '/enterprisereport',
    shouldDisplay: (
      userInfo: IUserInfoState,
    ): boolean => isAdmin(userInfo) || hasRole(userInfo.roles, 'Enterprise'),
    children: [
      {
        label: 'Multi-Account Messages',
        target: '/legacy/multiaccountsend',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          userInfo.accounts?.length > 1
          && hasPermission(userInfo.permissions, 'Enterprise:Read')
        ),
      },
      {
        label: 'Enterprise Report',
        target: '/enterprisereport',
        shouldDisplay: (
          userInfo: IUserInfoState,
        ): boolean => isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Reports:93'),
      },
      {
        label: 'Enterprise Text Report',
        target: '/enterprisetextreport',
        shouldDisplay: (
          userInfo: IUserInfoState,
        ): boolean => isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Reports:115'),
      },
      {
        label: 'Surveys Dashboard',
        target: '/enterprisesurveys/pbi/report',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          // 91 - EnterpriseSurveyStaffReport - read (View Staff Report)
          // 92 - EnterpriseSurveyResidentReport - read (View Resident Report)
          (isAdmin(userInfo) || (hasPermission(userInfo.permissions, 'Broadcast Forms:91') && hasPermission(userInfo.permissions, 'Broadcast Forms:92')))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'multi-account-surveys')
        ),
      },
      {
        label: 'Staff Surveys Report',
        target: '/enterprisestaffsurveys/pbi/report',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          // 91 - EnterpriseSurveyStaffReport - read (View Staff Report)
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Broadcast Forms:91'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'multi-account-surveys')
        ),
      },
      {
        label: 'Resident Surveys Report',
        target: '/enterpriseresidentsurveys/pbi/report',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          // 92 - EnterpriseSurveyResidentReport - read (View Resident Report)
          (isAdmin(userInfo) || hasPermission(userInfo.permissions, 'Broadcast Forms:92'))
          && hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'multi-account-surveys')
        ),
      },
    ],
  },
  {
    label: 'Administration',
    icon: 'toolbox',
    target: '/legacy/administration',
    children: [
      {
        label: 'Reports',
        target: '/legacy/usagereports',
      },
      {
        label: 'Account Settings',
        target: '/legacy/accountsettings',
      },
      // {
      //   label: 'Discharged Patient Configuration',
      //   target: '/discharged-patient',
      //   shouldDisplay: (
      //     userInfo: IUserInfoState,
      //   ): boolean => isSuper(userInfo) && hasRole(userInfo.roles, 'DischargedPatients'),
      // },
      {
        label: 'Message Settings',
        target: '/legacy/messagesettings',
      },
      {
        label: 'Other',
        target: '/legacy/administration',
      },
    ],
  },
  {
    label: 'Internal',
    icon: 'toolbox',
    target: '',
    shouldDisplay: (userInfo: IUserInfoState) => isSuper(userInfo),
    children: [
      {
        label: 'Account Configuration',
        target: '/internal/accountsettings',
      },
      {
        label: 'Reserved Domains',
        target: '/internal/reserveddomains',
        shouldDisplay: (
          userInfo: IUserInfoState,
        ): boolean => isVFCorpAccount(userInfo),
      },
      {
        label: 'Multi-Account Surveys',
        target: '/internal/multiaccountsurvey',
        shouldDisplay: (userInfo: IUserInfoState): boolean => (
          hasFeature(userInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [], 'multi-account-surveys')
        ),
      },
    ],
  },
  {
    label: 'Help',
    icon: 'question',
    target: 'https://help.goicon.com/',
    external: true,
    useNewWindow: true,
    callBack: () => {
      segmentAnalyticsTrack(trackActions.helpLink());
    },
  },
];

export default MainNavItems;
