import React from 'react';
import { Helmet } from 'react-helmet';
import { useQueryParam } from 'use-query-params';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr/immutable';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import { Container, Spinner } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { LANGUAGES } from 'lib/CommonSelect';
import Select from 'components/Inputs/Select';
import { BroadcastSurveyForm } from './BroadcastSurveyForm';

const BroadcastFormSession = () => {
  const [formSessionId] = useQueryParam<string>('id');
  const { formId } = useParams<{formId: string}>();
  const [targetLanguage] = useQueryParam<string>('targetLanguage');
  const [isSurveySuccess, setIsSurveySuccess] = React.useState(false);
  const history = useHistory();
  const { data: form, error, isLoading } = useSWR(
    ['broadcastForm', formId, formSessionId, targetLanguage],
    () => BroadcastFormApi.getFormSessionById(
      formId,
      formSessionId,
      targetLanguage ?? '',
      {
        // to prevent from redirecting
        ignoreGlobalCatch: true,
      },
    ),
    {
      shouldRetryOnError: false,
      // override default error handler that shows toasts
      onError: (err) => {
      },
    });
  let content: React.ReactNode;
  if (error) {
    const errorMessage = (error as AxiosError).response?.data?.Message
      ?? 'Something went wrong';
    content = <p className="broadcast-form-session__error">{errorMessage}</p>;
  } else if (isLoading || !form) {
    content = (
      <Spinner
        animation="border"
        variant="primary"
        className="broadcast-form-session__spinner"
      />
    );
  } else if (!form.Form) {
    content = <p className="broadcast-form-session__error">Survey not found</p>;
  } else if (!form.Profile) {
    content = <p className="broadcast-form-session__error">No profile data found.</p>;
  } else if (form.Form.IsComplete) {
    content = <p className="broadcast-form-session__error">This survey is no longer accepting submissions</p>;
  } else {
    content = (
      <BroadcastSurveyForm
        formId={formId}
        sessionId={formSessionId}
        form={form}
        onSuccess={() => setIsSurveySuccess(true)}
      />
    )
  }
  return (
    <div className="broadcast-form-session">
      <Helmet>
        <style type="text/css">
          {`${form?.Branding?.BrandColor
            ? `
            :root {
              --broadcast-form-brand-color: ${form?.Branding?.BrandColor}; 
            }`
            : ''
          }
          html, body {
            background-color: ${form?.Branding?.BackgroundColor};
          }
        `}
        </style>
        <title>Submit Form</title>
      </Helmet>
      <Container>
        <div className="broadcast-form-session__form-wrapper">
          {content}
        </div>
      </Container>
      {!isLoading && form && !error && (!form?.Responses?.length ? !isSurveySuccess : !form?.Responses?.length) && (
        <div className="broadcast-form-session__language_picker_div">
          <div className="broadcast-form-session__language_picker">
            <Select
              id="Language"
              name="Language"
              label="Language"
              placeholder="Select language"
              defaultValue={form?.CurrentLanguage}
              options={LANGUAGES}
              onChange={(evt) => {
                history.push(`/form/${formId}?id=${formSessionId}&targetLanguage=${evt}`);
              }}
            />
          </div>
          <p className="broadcast-form-session__language_picker_p">Note: Changing the language will reset the survey.</p>
        </div>
      ) }
    </div>
  )
}

export default BroadcastFormSession;
